jQuery(document).ready(function ($) {
    JobListing = function () {

        var self = this;
        var jobIndustries = $("#job_listing_industries input[type='checkbox']");
        var jobListingEmploymentTypes = $("#job_listing_employment_types input[type='checkbox']");

        // PRICE FILTER
        var $range = $("#workload input[type='text']");

        $range.ionRangeSlider({
            skin: "round",
            type: "double",
            min: 0,
            max: 100,
            step: 10,
            postfix: "%",
            onFinish: function (data) {
                self.filter_jobs();
                self.filter_jobs_count();
            },
        });

        // INPUT TAGS
        var $tag = $('[name=tags]').tagify({duplicates: false});

        //Live Search
        $('.live_search').on('keyup', function () {

            let thVla = $(this).val().toLowerCase();

            if (thVla.length >= 2) {
                $(this).closest('.select-form-group').addClass('live-search-open');
                $(this).closest('div').find('.search-field li').each(function (i, e) {
                    if ($(e).text().toLowerCase().indexOf(thVla) >= 0) {
                        $(e).removeClass('d-none');
                    } else {
                        $(e).addClass('d-none');
                    }
                });
            } else {
                $(this).closest('div').find('.search-field li').removeClass('d-none');
                $(this).closest('.select-form-group').removeClass('live-search-open');
            }

        });

        $(document).click(function (event) {
            if (!$(event.target).closest(".select-form-group").length) {
                $('.select-form-group').removeClass('live-search-open');
            }
        });

        $('.jobs__filter-search-btn').on('click', function (e) {
            e.preventDefault();
            self.filter_jobs();
            self.filter_jobs_count();
        });

        $('.search-field li a').click(function (e) {
            e.preventDefault();
            $(this).closest('form').find('.live_search').val($(this).text());
            $('.select-form-group').removeClass('live-search-open');
            self.filter_jobs();
            self.filter_jobs_count();
        });

        $('.jobs__popup-search-btn').on('click', function (e) {
            e.preventDefault();
            self.jobs_popup_search();
        });

        jobIndustries.on('change', function () {
            self.filter_jobs();
            self.filter_jobs_count();
        });

        jobListingEmploymentTypes.on('change', function () {
            self.filter_jobs();
            self.filter_jobs_count();
        });

        var search_form = $('#jobs__filter-search-form');
        var search_form_modal = $('#jobs__popup-search-form');

        console.log(search_form.length);

        self.filter_jobs = function () {

            if (search_form.length == 0) return;

            var requestUrl = self.requestUrl('jobs_filter');

            self.save_filter_jobs();

            $.ajax({
                method: "POST",
                url: requestUrl
            }).done(function (data) {
                $('.jobs__result').html(data);
            });
        }

        self.filter_jobs_count = function () {

            if (search_form.length == 0) return;

            var requestUrl = self.requestUrl('jobs_filter_count');

            var notCheckedJobIndustries = getFilterNotCheckedItems(jobIndustries);
            if (notCheckedJobIndustries.length !== 0) {
                requestUrl += ('&job_listing_not_industries=' + notCheckedJobIndustries.toString());
            }

            var notCheckedEmploymentTypes = getFilterNotCheckedItems(jobListingEmploymentTypes);
            if (notCheckedEmploymentTypes.length !== 0) {
                requestUrl += ('&job_listing_not_employment_types=' + notCheckedEmploymentTypes.toString());
            }

            $.ajax({
                method: "POST",
                dataType: "json",
                url: requestUrl
            }).done(function (data) {
                if (data.success) {
                    $.each(data.data.counter, function (i, e) {
                        $.each(e, function (ii, ee) {
                            $('#' + i + ' input[value="' + ii + '"]').closest('.custom-control').find('.custom-control-label span').html(ee);
                        });
                    });
                }
            });
        }

        self.jobs_popup_search = function () {
            var requestUrl = themeJsVars.ajaxurl + "?action=jobs_popup_search";

            if ($('input.live-search-field', search_form_modal)) {
                requestUrl += '&job_listing_locations=' + $('input.live-search-field', search_form_modal).val();
            }

            if ($('input.jobs__popup-search-input', search_form_modal)) {
                requestUrl += '&s=' + $('input.jobs__popup-search-input', search_form_modal).val();
            }

            $.ajax({
                method: "POST",
                url: requestUrl
            }).done(function (data) {
                $('.latest-jobs__body_popup').html(data);
            });
        }

        self.requestUrl = function (action) {

            if (search_form.length == 0) return;

            var requestUrl = themeJsVars.ajaxurl + "?action=" + action;

            var checkedJobIndustries = getFilterCheckedItems(jobIndustries);
            if (checkedJobIndustries.length !== 0) {
                requestUrl += '&job_listing_industries=' + checkedJobIndustries.toString();
            }

            var CheckedEmploymentTypes = getFilterCheckedItems(jobListingEmploymentTypes);
            if (CheckedEmploymentTypes.length !== 0) {
                requestUrl += '&job_listing_employment_types=' + CheckedEmploymentTypes.toString();
            }

            if ($range.data("ionRangeSlider")) {
                requestUrl += '&workload_from=' + $range.data("ionRangeSlider").result.from;
                requestUrl += '&workload_to=' + $range.data("ionRangeSlider").result.to;
            }

            if ($('input.live-search-field', search_form)) {
                requestUrl += '&job_listing_locations=' + $('input.live_search', search_form).val();
            }

            if ($tag.data('tagify') && $tag.data('tagify').value !== '') {
                let filterSearchValue = $tag.data('tagify').value;
                var array_search = [];
                for (var k in filterSearchValue) {
                    array_search.push(filterSearchValue[k].value);
                }
                requestUrl += '&s=' + array_search.join('||');
            }

            if ($('[name="current_ID"]', search_form).length > 0) {
                requestUrl += '&current_ID=' + $('[name="current_ID"]', search_form).val();
            }

            return requestUrl;

        }

        self.save_filter_jobs = function () {

            if (search_form.length == 0) return;

            let employment_types = [];
            $('#job_listing_employment_types input[type="checkbox"]:checked').each(function () {
                employment_types.push($(this).val());
            });

            let industries = [];
            $('#job_listing_industries input[type="checkbox"]:checked').each(function () {
                industries.push($(this).val());
            });

            let live_search = $('input.live_search', search_form).val();

            var array_tags = [];
            if ($tag.data('tagify') && $tag.data('tagify').value !== '') {
                let filterSearchValue = $tag.data('tagify').value;
                for (var k in filterSearchValue) {
                    array_tags.push(filterSearchValue[k].value);
                }
            }

            if ($range.data("ionRangeSlider")) {
                let workload_to = $range.data("ionRangeSlider").result.to;
                let workload_from = $range.data("ionRangeSlider").result.from;
                localStorage.setItem('workload_to', workload_to);
                localStorage.setItem('workload_from', workload_from);
            }

            localStorage.setItem('live_search', live_search);
            localStorage.setItem('tags', array_tags);
            localStorage.setItem('employment_types', employment_types);
            localStorage.setItem('industries', industries);
        }

        self.load_filter_jobs = function () {

            if (search_form.length == 0) return;

            let get_live_search = localStorage.getItem('live_search');
            let get_tags = localStorage.getItem('tags');
            let get_employment_types = localStorage.getItem('employment_types');
            let get_workload_to = localStorage.getItem('workload_to');
            let get_workload_from = localStorage.getItem('workload_from');
            let get_industries = localStorage.getItem('industries');

            let range_to = 100;
            if (get_workload_to * 1 > 0) {
                range_to = get_workload_to;
            }

            let range_from = 0;
            if (get_workload_from * 1 > 0) {
                range_from = get_workload_from;
            }

            if ($range.data("ionRangeSlider")) {
                $range.data("ionRangeSlider").update({
                    to: range_to,
                    from: range_from,
                });
            }

            if (get_industries) {
                $.each(get_industries.split(','), function (i, e) {
                    $('#job_listing_industries input[value="' + e + '"]').prop('checked', true);
                });
            }

            if (get_employment_types) {
                $.each(get_employment_types.split(','), function (i, e) {
                    $('#job_listing_employment_types input[value="' + e + '"]').prop('checked', true);
                });
            }

            if (get_tags && $tag.data('tagify')) {
                $tag.data('tagify').addTags(get_tags.split(','));
            }

            $('#jobs__filter-search-form input.live_search').val(get_live_search);
        }

        self.load_filter_jobs();
        self.filter_jobs();
        self.filter_jobs_count();

        function getFilterCheckedItems(filterInputs) {
            var arr = [];

            filterInputs.each(function () {
                if ($(this).is(':checked')) {
                    arr.push($(this).val());
                }
            });

            return arr;
        }

        function getFilterNotCheckedItems(filterInputs) {
            var arr = [];

            filterInputs.each(function () {
                arr.push($(this).val());
            });

            return arr;
        }
    }
    JobListing();
});