jQuery(document).ready(function ($) {

    var viewportHeight = $(window).outerHeight();

    $('[data-action="print-pdf"]').on('click', function (e) {
        e.preventDefault();
        window.print();
    });

    $('[data-action="download-pdf"]').on('click', function (e) {
        e.preventDefault();
        window.print();
    });

    // Copy-link
    $('[data-action="copy-link-url"]').on('click', function (e) {
        e.preventDefault();
        copyToClipboard($(this).attr('href'));
        var $label = $(this).find('span');
        $label.addClass('show');
        setTimeout(function () {
            $label.removeClass('show');
        }, 1000);
    });

    let $btnRapport = document.querySelector('.btn-rapport');
    let $introScrollDown = document.querySelector('.intro__scroll-down');
    if ( $btnRapport !== null && $introScrollDown !== null && window.getComputedStyle($btnRapport).display !== 'none' ) {
        let originPos = parseInt($btnRapport.getBoundingClientRect().top);
        let posOffest = parseInt($introScrollDown.getBoundingClientRect().top+10);
        $btnRapport.style.top = posOffest+"px";

        $(window).scroll(function(){
            if ( window.scrollY > 100 ) {
                var $newPos = originPos;
            } else {
                var $newPos = posOffest;
            }

            if ( $newPos !== parseInt($btnRapport.getBoundingClientRect().top) ) {
                $('.btn-rapport').animate({
                    top: $newPos+"px",
                }, 200 );
            }
        });

    }

    function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            return clipboardData.setData("Text", text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    //PRELOADER
    if ($('.preloader').length > 0) {
        var timelineW = new TimelineMax();

        timelineW
            .to('.preloader__img', .5, {
                autoAlpha: 0
            })
            .to('body', 0.1, {
                className: "+=preloader-show",
            })
            .to('.preloader__img', 2, {
                autoAlpha: 1
            })
            .to('.preloader__bg', 1.5, {
                height: viewportHeight,
            })
            .to('.preloader', 1, {
                autoAlpha: 0
            })
            .to('body', 0, {
                className: "-=preloader-show",
            })
    }

    //Close Cookies
    $('.cookies .btn, .cookies__close').click(function (e) {
        e.preventDefault();
        $('.cookies').fadeOut();
    });


    // MENU
    if ($(window).width() > 1199) {
        $('.menu-item-has-children').mouseenter(function () {
            $(this).find('ul').stop().fadeIn();
            $('.header').addClass('menu-children-open');
        }).mouseleave(function () {
            $(this).find('ul').stop().fadeOut();
            $('.header').removeClass('menu-children-open');
        });
    } else {
        $('.menu-item-has-children span').on('click', function () {
            if ($(this).closest('li').hasClass('opened_sub_menu')) {
                $(this).closest('li').removeClass('opened_sub_menu');
                $(this).closest('li').find('.main-sub-menu').stop().slideUp();
            } else {
                $('.menu-item-has-children .main-sub-menu').stop().slideUp();
                $('.menu-item-has-children').removeClass('opened_sub_menu');
                $(this).closest('li').addClass('opened_sub_menu');
                $(this).closest('li').find('.main-sub-menu').stop().slideDown();
            }
        });
    }

    // RIGHT NAVBAR
    $(document).on("click", ".open-job", function (e) {
        e.preventDefault();
        $('body').addClass('opened-job-bar');
    });
    $(document).on("click", ".open-contact", function (e) {
        e.preventDefault();
        $('body').addClass('opened-contact-bar');
    });
    $(document).on("click", ".close-bar", function (e) {
        e.preventDefault();
        $('body').removeClass('opened-job-bar opened-contact-bar');
    });

    // HOME REVIEW SLIDER
    $('.review-slider').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 7000,
        draggable: false,
        swipe: false,
        touchMove: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    draggable: true,
                    swipe: true,
                    touchMove: true,
                }
            }
        ]
    });


    // HOME STEPS ANIMATION
    var controller = new ScrollMagic.Controller();

    var timeline = new TimelineMax();

    if ($(window).width() > 767) {
        timeline
            .fromTo('.home-step__body > div:first-child .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:first-child .home-step__block-head', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:first-child .home-step__block-body', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:first-child .home-step__block', 1, {
                delay: 100,
                className: "-=active",
            })
            .fromTo('.home-step__body > div:nth-child(2) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block-head', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block-body', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })
            .fromTo('.home-step__body > div:nth-child(3) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block-head', 0, {

                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block-body', 0, {
                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })
            .fromTo('.home-step__body > div:nth-child(4) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block-head', 0, {

                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block-body', 0, {
                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })

        var scene = new ScrollMagic.Scene({
            triggerElement: '.home-step',
            duration: '100%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline)
            .setPin('.home-step')
            .addTo(controller)
    } else {
        timeline
            .fromTo('.home-step__body > div:first-child .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:first-child .home-step__block-head', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:first-child .home-step__block-body', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:first-child .home-step__block', 1, {
                delay: 100,
                className: "-=active",
            })
            .to('.home-step__body > div:first-child .home-step__block', 0, {
                opacity: 0,
            })
            .fromTo('.home-step__body > div:nth-child(2) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block-head', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block-body', 0, {
                opacity: 1,
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })
            .to('.home-step__body > div:nth-child(2) .home-step__block', 0, {
                opacity: 0,
            })
            .fromTo('.home-step__body > div:nth-child(3) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block-head', 0, {

                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block-body', 0, {
                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })
            .to('.home-step__body > div:nth-child(3) .home-step__block', 0, {
                opacity: 0,
            })
            .fromTo('.home-step__body > div:nth-child(4) .home-step__block-line', 1, {width: 0}, {
                width: "100%",
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block-head', 0, {

                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block-body', 0, {
                opacity: (1),
            })
            .to('.home-step__body > div:nth-child(4) .home-step__block', 1, {
                delay: 100,
                className: "-=active"
            })

        var scene = new ScrollMagic.Scene({
            triggerElement: '.home-step__body',
            duration: '200%',
            triggerHook: "onLeave",
            offset: -50
        })
            .setTween(timeline)
            .setPin('.home-step__body')
            .addTo(controller)
    }


    // HOME TIPS ANIMATION

    var timeline2 = new TimelineMax();

    timeline2
        .fromTo('.home-tips__list li:nth-child(1)', 1, {opacity: 0}, {
            opacity: 1,
        })
        .to('.home-tips__list li:nth-child(1)', 1, {
            delay: 100,
            className: "-=active"
        })
        .to('.home-tips__list li:nth-child(2)', 0, {
            opacity: (1),
        })
        .to('.home-tips__list li:nth-child(2)', 1, {
            delay: 100,
            className: "-=active"
        })
        .to('.home-tips__list li:nth-child(3)', 0, {
            opacity: (1),
        })
        .to('.home-tips__list li:nth-child(3)', 1, {
            delay: 100,
            className: "-=active"
        })
        .to('.home-tips__list li:nth-child(4)', 0, {
            opacity: (1),
        })
        .to('.home-tips__list li:nth-child(4)', 1, {
            delay: 100,
            className: "-=active"
        })

    if ($(window).width() > 767) {
        var scene2 = new ScrollMagic.Scene({
            triggerElement: '.home-tips',
            duration: '100%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline2)
            .setPin('.home-tips')
            .addTo(controller)
    } else {
        var scene2 = new ScrollMagic.Scene({
            triggerElement: '.home-tips',
            duration: '100%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline2)
            .addTo(controller)
    }


    // Home Reason Company Animation

    var timeline3 = new TimelineMax();

    if ($(window).width() > 767) {

        timeline3
            .to('.reason-company__bg  .reason-company__bg-img:nth-child(2)', 2, {
                delay: 1,
                y: 0,
            })
            .to('.reason-company__slider  .reason-company__slider-block:nth-child(1)', 0, {
                opacity: 0,
            }, "-=2")
            .to('.reason-company__slider .reason-company__slider-block:nth-child(2)', 0, {
                opacity: 1,
            }, "-=2")
            .to('.reason-company__bg  .reason-company__bg-img:nth-child(3)', 2, {
                y: 0,
            })
            .to('.reason-company__slider  .reason-company__slider-block:nth-child(2)', 0, {
                opacity: 0,
            }, "-=2")
            .to('.reason-company__slider .reason-company__slider-block:nth-child(3)', 0, {
                opacity: 1,
            }, "-=2")

        var scene3 = new ScrollMagic.Scene({
            triggerElement: '.reason-company',
            duration: '300%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline3)
            .setPin('.reason-company')
            .addTo(controller)

    } else {

        timeline3
            .to('.reason-company__right.hidden-animate', 1, {
                display: 'none',
            })
            .to('.reason-company__head .hidden-animate', 1, {
                display: 'none',
            }, "-=1")
            .to('.reason-company__slider  .reason-company__slider-block:nth-child(1)', 1, {
                opacity: 1,
            })
            .to('.reason-company__bg  .reason-company__bg-img:nth-child(2)', 2, {
                delay: 1,
                y: 0,
            })
            .to('.reason-company__slider  .reason-company__slider-block:nth-child(1)', 0, {
                opacity: 0,
            }, "-=2")
            .to('.reason-company__slider .reason-company__slider-block:nth-child(2)', 0, {
                opacity: 1,
            }, "-=2")
            .to('.reason-company__bg  .reason-company__bg-img:nth-child(3)', 2, {
                y: 0,
            })
            .to('.reason-company__slider  .reason-company__slider-block:nth-child(2)', 0, {
                opacity: 0,
            }, "-=2")
            .to('.reason-company__slider .reason-company__slider-block:nth-child(3)', 0, {
                opacity: 1,
            }, "-=2")

        var scene3 = new ScrollMagic.Scene({
            triggerElement: '.reason-company',
            duration: '400%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline3)
            .setPin('.reason-company')
            .addTo(controller)

    }

    // DIGNITY SLIDER


    var timeline4 = new TimelineMax();

    if ($(window).width() > 767) {
        timeline4
            .to('.principles__slider', 1, {
                delay: 1,
                x: '-160%'
            })


        var scene4 = new ScrollMagic.Scene({
            triggerElement: '.principles',
            duration: '100%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline4)
            .setPin('.principles')
            .addTo(controller)
    } else if ($(window).width() > 374 && $(window).width() < 767) {

        timeline4
            .to('.principles__slider', 1, {
                x: '-100%'
            })


        var scene4 = new ScrollMagic.Scene({
            triggerElement: '.principles',
            duration: '100%',
            triggerHook: "onLeave",
            offset: 0
        })
            .setTween(timeline4)
            .setPin('.principles')
            .addTo(controller)
    } else {
        timeline4
            .to('.principles__slider', 1, {
                x: '-100%'
            })


        var scene4 = new ScrollMagic.Scene({
            triggerElement: '.principles__slider',
            duration: '100%',
            triggerHook: "onLeave",
            offset: -30
        })
            .setTween(timeline4)
            .setPin('.principles__slider')
            .addTo(controller)
    }


    // Float labels
    $(document).on('focusin', '.contact-form input, .contact-form textarea', function () {
        $(this).closest('div').addClass('not-empty');
    });
    $(document).on('focusout', '.contact-form input, .contact-form textarea', function () {
        var $this = $(this);
        if ($this.val() == '') {
            $this.closest('div').addClass('empty').removeClass('not-empty');
        } else {
            $this.closest('div').addClass('not-empty').removeClass('empty');
        }
    });

    $('.contact-form').on('wpcf7mailsent ', function () {
        $(this).find('.not-empty').removeClass('not-empty');
    });

    //SCROLL TOP
    $('.scroll-top').click(function (e) {
        e.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, 1200);
        return false;
    });

    //SCROLL DOWN
    $('.intro__scroll-down, .scroll-down').on('click', function (e) {
        if ($(window).width() > 1199) {
            var iddatatop = 110;
        } else {
            var iddatatop = 99;
        }

        var href = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(href).offset().top /*- iddatatop*/
        }, '1200');
        e.preventDefault();
    });

    //SELECT
    !function (e) {
        e.fn.niceSelect = function (t) {
            function s(t) {
                t.after(e("<div></div>").addClass("nice-select").addClass(t.attr("class") || "").addClass(t.attr("disabled") ? "disabled" : "").attr("tabindex", t.attr("disabled") ? null : "0").html('<span class="current"></span><ul class="list"></ul>'));
                var s = t.next(), n = t.find("option"), i = t.find("option:selected");
                s.find(".current").html(i.data("display") || i.text()), n.each(function (t) {
                    var n = e(this), i = n.data("display");
                    s.find("ul").append(e("<li></li>").attr("data-value", n.val()).attr("data-display", i || null).addClass("option" + (n.is(":selected") ? " selected" : "") + (n.is(":disabled") ? " disabled" : "")).html(n.text()))
                })
            }

            if ("string" == typeof t) return "update" == t ? this.each(function () {
                var t = e(this), n = e(this).next(".nice-select"), i = n.hasClass("open");
                n.length && (n.remove(), s(t), i && t.next().trigger("click"))
            }) : "destroy" == t ? (this.each(function () {
                var t = e(this), s = e(this).next(".nice-select");
                s.length && (s.remove(), t.css("display", ""))
            }), 0 == e(".nice-select").length && e(document).off(".nice_select")) : console.log('Method "' + t + '" does not exist.'), this;
            this.hide(), this.each(function () {
                var t = e(this);
                t.next().hasClass("nice-select") || s(t)
            }), e(document).off(".nice_select"), e(document).on("click.nice_select", ".nice-select", function (t) {
                var s = e(this);
                e(".nice-select").not(s).removeClass("open"), s.toggleClass("open"), s.hasClass("open") ? (s.find(".option"), s.find(".focus").removeClass("focus"), s.find(".selected").addClass("focus")) : s.focus()
            }), e(document).on("click.nice_select", function (t) {
                0 === e(t.target).closest(".nice-select").length && e(".nice-select").removeClass("open").find(".option")
            }), e(document).on("click.nice_select", ".nice-select .option:not(.disabled)", function (t) {
                var s = e(this), n = s.closest(".nice-select");
                n.find(".selected").removeClass("selected"), s.addClass("selected");
                var i = s.data("display") || s.text();
                n.find(".current").text(i), n.prev("select").val(s.data("value")).trigger("change")
            }), e(document).on("keydown.nice_select", ".nice-select", function (t) {
                var s = e(this), n = e(s.find(".focus") || s.find(".list .option.selected"));
                if (32 == t.keyCode || 13 == t.keyCode) return s.hasClass("open") ? n.trigger("click") : s.trigger("click"), !1;
                if (40 == t.keyCode) {
                    if (s.hasClass("open")) {
                        var i = n.nextAll(".option:not(.disabled)").first();
                        i.length > 0 && (s.find(".focus").removeClass("focus"), i.addClass("focus"))
                    } else s.trigger("click");
                    return !1
                }
                if (38 == t.keyCode) {
                    if (s.hasClass("open")) {
                        var l = n.prevAll(".option:not(.disabled)").first();
                        l.length > 0 && (s.find(".focus").removeClass("focus"), l.addClass("focus"))
                    } else s.trigger("click");
                    return !1
                }
                if (27 == t.keyCode) s.hasClass("open") && s.trigger("click"); else if (9 == t.keyCode && s.hasClass("open")) return !1
            });
            var n = document.createElement("a").style;
            return n.cssText = "pointer-events:auto", "auto" !== n.pointerEvents && e("html").addClass("no-csspointerevents"), this
        }
    }(jQuery);

    //SELECT INIT
    $('select').niceSelect();

    var telInput = $("#phone"),
        errorMsg = $("#error-msg"),
        validMsg = $("#valid-msg");

    // initialise plugin
    telInput.intlTelInput({

        allowExtensions: true,
        formatOnDisplay: true,
        autoFormat: true,
        autoHideDialCode: true,
        autoPlaceholder: false,
        defaultCountry: "ch",
        ipinfoToken: "yolo",

        nationalMode: false,
        numberType: "MOBILE",
        onlyCountries: ['ch', 'us', 'gb', 'ca', 'do'],
        preferredCountries: ['sa', 'ae', 'qa', 'om', 'bh', 'kw', 'ma'],
        preventInvalidNumbers: true,
        separateDialCode: true,
        initialCountry: "ch",
        geoIpLookup: function (callback) {
            $.get("http://ipinfo.io", function () {
            }, "jsonp").always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "";
                callback(countryCode);
            });
        },
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.9/js/utils.js"
    });

    $('#phone').mask('00 000 00 00');

    // Float labels
    $(document).on('focusin', '.form-control', function () {
        $(this).closest('div').addClass('not-empty');
    });
    $(document).on('focusout', '.form-control', function () {
        var $this = $(this);
        if ($this.val() == '') {
            $this.closest('div').addClass('empty').removeClass('not-empty');
        } else {
            $this.closest('div').addClass('not-empty').removeClass('empty');
        }
    });

    $(document).on('change', 'select', function (e) {
        $(this).closest('.form-group').addClass('active');
        $(this).closest('.form-group').find('label.error').hide();
        $(this).removeClass('error');
    });

    $(document).on("click", ".nice-select .option:not(.disabled)", function () {

        var s = $(this),
            n = s.closest(".nice-select");

        console.log(s.data("value"));
        $(this).addClass('active');
    });

    // Latest Jobs Slider
    $('.latest-jobs-slider').slick({
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slick-prev"><i class="fas fa-chevron-up"></i></button>',
        nextArrow: '<button class="slick-next"><i class="fas fa-chevron-down"></i></button>',
        infinite: false,
        draggable: false,
        swipe: false,
        touchMove: false,
        vertical: true,
        touchThreshold: 30,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    $('.latest-jobs-slider').on('wheel', (function (e) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
            $(this).slick('slickPrev');
        } else {
            $(this).slick('slickNext');
        }
    }));

    // MOBILE MENU
    $('.openMenu').click(function () {
        if ($('body').hasClass('opened-menu')) {
            $('body').removeClass('opened-menu');
            $('.main_menu_wrapper').fadeOut(600);
            $('.lang-list').fadeOut(600);

        } else {
            $('body').addClass('opened-menu');
            $('.main_menu_wrapper').fadeIn(600);
            $('.lang-list').fadeIn(600);
        }
    });

    // ANIMATION
    AOS.init({
        duration: 700,
        delay: 100,
        easing: 'ease'
    });

    setTimeout(function () {
        AOS.refresh();
    }, 500);


    //Validator
    $(".application__form").validate({
        ignore: [],
        errorPlacement: function (error, element) {
            error.insertBefore(element);
        }
    });

    if ($(window).width() > 767) {
        $('.scroll-block').niceScroll({
            cursorborder: "8",
            cursorcolor: '#ddd',
            cursorwidth: 8,
            cursorborderradius: 5,
            horizrailenabled: false,
            background: "#f7f7f7",
            touchbehavior: true,
            emulatetouch: true,
            railoffset: {left: 20}
        });
        $('.country-list').niceScroll({
            cursorborder: "8",
            cursorcolor: '#ddd',
            cursorwidth: 2,
            cursorborderradius: 2,
            horizrailenabled: false,
            background: "#f7f7f7",
            touchbehavior: true,
            emulatetouch: true,
        });
    }

    //FILTER
    $(document).on('click', '.jobs__filter-block-title', function (e) {
        $(this).toggleClass("active");
        $(this).closest('.jobs__filter-block').find('.jobs__filter-body').slideToggle();
    });

    $(document).on('click', '.jobs__filter-title', function (e) {
        $(this).toggleClass("active");
        $(this).closest('.jobs__filter').find('.jobs__filter-body').slideToggle();
    });

    //Counter
    $('.counter').counterUp({
        time: 1000
    });

    // HOME REVIEW SLIDER
    var $teamCarousel = $('.team-slider');

    $teamCarousel.slick({
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slick-prev"><i class="icon-left-arrow"></i></button>',
        nextArrow: '<button class="slick-next"><i class="icon-right-arrow"></i></button>',
        infinite: true,
        draggable: false,
        swipe: false,
        touchMove: false,
        variableWidth: true,
    });

    $teamCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var
            direction,
            slideCountZeroBased = slick.slideCount - 1;

        if (nextSlide == currentSlide) {
            direction = "same";

        } else if (Math.abs(nextSlide - currentSlide) == 1) {
            direction = (nextSlide - currentSlide > 0) ? "right" : "left";

        } else {
            direction = (nextSlide - currentSlide > 0) ? "left" : "right";
        }

        // Add a temp CSS class for the slide animation (.slick-current-clone-animate)
        if (direction == 'right') {
            $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $teamCarousel).addClass('slick-current-clone-animate');
        }

        if (direction == 'left') {
            $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $teamCarousel).addClass('slick-current-clone-animate');
        }
    });

    $teamCarousel.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $('.slick-current-clone-animate', $teamCarousel).removeClass('slick-current-clone-animate');
        $('.slick-current-clone-animate', $teamCarousel).removeClass('slick-current-clone-animate');
    });

    function circle() {
        var ww = window.innerWidth;
        var dw = $('.circle-animation').innerWidth();
        var dTr = ww - 704 + "px";

        $('.circle-animation li:first-child').addClass('active');
        setTimeout(function () {
            $('.circle-animation li:nth-child(2)').addClass('active');
        }, 500);
        setTimeout(function () {
            $('.circle-animation li:nth-child(3)').addClass('active');
        }, 1000);
        setTimeout(function () {
            $('.circle-animation li:nth-child(4)').addClass('active');
        }, 1500);
        if (ww < 650) {
            $('.circle-animation').css('transform', 'translateX(' + dTr + ')');
        }
    }

    function circle2() {
        $('.two-circle li').addClass('active');
    }

    // Scripts which runs at window scroll
    window.onscroll = function () {
        /*if ($(window).scrollTop()>50)
            $('body').addClass('collapse-header');
        else $('body').removeClass('collapse-header');*/

        if ($('.circle-animation').length > 0) {
            if ($(window).scrollTop() > $('.circle-animation').offset().top - window.innerHeight / 2) {
                circle();
            }
        }

        if ($('.two-circle').length > 0) {
            if ($(window).scrollTop() > $('.two-circle').offset().top - window.innerHeight / 2) {
                circle2();
            }
        }
        if ($('.partner__title-block').length > 0) {
            if ($(window).scrollTop() > $('.partner__title-block').offset().top - window.innerHeight / 2) {
                $('.partner__title').addClass('active');
            }
        }
    };

    $.fn.filepond.registerPlugin(FilePondPluginImagePreview);

// Get a reference to the file input element
    const inputElement = document.querySelector('input.my-pond');

// Create a FilePond instance
    const pond = FilePond.create(inputElement);

    pond.on('addfile', function (e) {
        $('.upload__body').addClass('active');
    });

    pond.on('removefile', function (e) {
        if ($('.filepond--item').length < 2) {
            $('.upload__body').removeClass('active');
        }
    });

    $('.upload__submit-btn').on('click', function (e) {
        e.preventDefault();
        uploadFiles();
    });

    function uploadFiles() {

        var pondFiles = pond.getFiles(),
            requestUrl = themeJsVars.ajaxurl + "?action=upload_files",
            filesToLoad = new FormData();

        pondFiles.forEach(function (item) {
            filesToLoad.append(item.file.name, item.file);
        });

        $.ajax({
            method: "POST",
            url: requestUrl,
            data: filesToLoad,
            dataType: 'json',
            processData: false,
            contentType: false,
        }).error(function () {
            $('.upload__error-on-sending').show();
        }).done(function (data) {
            if (data.success) {
                $('.upload-done').removeClass('d-none');
                $('body').addClass('upload-done-page');
            } else {
                $('.upload__error-on-sending').show();
            }
        });
    }

    //Height
    function setHeight() {
        var viewportHeight = $(window).outerHeight();
        $('.full-height').css({height: viewportHeight});
    }

    setHeight();

    // Scripts which runs at window resize
    $(window).on('resize', function () {
        AOS.refresh();
        $(".scroll-block").getNiceScroll().resize();
    });

    // Scripts which runs at window orientationchange
    $(window).on('orientationchange', function () {
        setHeight();
        window.location.reload();
        $(".scroll-block").getNiceScroll().resize();
    });

    var homePreloaderCookieName = 'homePreloaderCookie';
    var cookiePopupName = 'cookiePopup';

    if (!getCookie(homePreloaderCookieName)) {
        setCookie(homePreloaderCookieName, 1, 1)
    }

    $('.accept-cookies').on('click', function () {
        if (!getCookie(cookiePopupName)) {
            setCookie(cookiePopupName, 1, 365)
        }
    })

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }

});

import './jobs-listing.js';
import './application-form.js';