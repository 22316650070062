$(document).ready(function () {
// Get a reference to the file input element
    const inputElement = document.querySelector('input.upload__filepond');

// Create a FilePond instance
    const pond = FilePond.create(inputElement);

    pond.on('addfile', function (e) {
        $('.upload__body').addClass('active');
    });

    pond.on('removefile', function (e) {
        if ($('.filepond--item').length < 2) {
            $('.upload__body').removeClass('active');
        }
    });

    $(".application__form").on('submit', function (e) {
        e.preventDefault();
        var $this = $(this);

        setTimeout(function () {
            if ($this.find('input.error, select.error').length) {
                return
            }
            applicationUploadFiles();
        }, 150);

    });

    function applicationUploadFiles() {

        var pondFiles = pond.getFiles(),
            requestUrl = themeJsVars.ajaxurl + "?action=application_upload_form",
            filesToLoad = new FormData(document.querySelector('form.application__form'));

        pondFiles.forEach(function (item) {
            filesToLoad.append(item.file.name, item.file);
        });

        $.ajax({
            method: "POST",
            url: requestUrl,
            data: filesToLoad,
            dataType: 'json',
            processData: false,
            contentType: false,
        }).error(function () {
            $('.upload__error-on-sending').show();
        }).done(function (data) {
            if (data.success) {
                var doneUrl = $('button[type="submit"]').data('done-url');
                window.location.replace(doneUrl);
            } else {
                $('.upload__error-on-sending').show();
            }
        });
    }

});
